import React from "react";

import styles from "./StartText.module.css";

const StartText = () => (
  <div className={styles.StartText}>
    <div className={styles.StartTextTitle}>
      Are you tired of feeling{" "}
      <span className={styles.StartTextGrayed}>ineffective</span> with your
      clients?
    </div>
    <div className={styles.StartTextMain}>
      {`
        E-mail, Google-documents, Whatsapp—all those are hopelessly outdated.
        They take a lot of your time and effort, and they are falling short
        of expectations. It's time to replace them with something modern.
      `}
    </div>
    <div className={styles.StartTextHighlighted}>
      FitLab would get you covered.
    </div>
  </div>
);

export default StartText;
